import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createRoot } from 'react-dom/client'

import { GlobalStyles } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'


import globalStyles from 'theme/GlobalStyle'

import '@fontsource/roboto'
// import "@fontsource/montserrat"

//import './i18n'

import muiTheme from 'theme/MuiTheme'

import NoMatch from 'pages/404'
import HomePage from 'pages/home/HomePage'
import AboutUsPage from 'pages/AboutUsPage'
import ContactUsPage from 'pages/ContactPage'
import CareersPage from 'pages/CareersPage'
import SupportPage from 'pages/SupportPage'
import ProductsPage from 'pages/ProductsPage'

import { HashRouter } from 'react-router-dom';

const inputGlobalStyles = <GlobalStyles styles={globalStyles} />

const container = document.getElementById('root')!
const root = createRoot(container)

 root.render(
  <ThemeProvider theme={muiTheme}>
  {inputGlobalStyles}
<BrowserRouter>
    <HomePage />
  </BrowserRouter>,
  </ThemeProvider>
);



// root.render((
// <HashRouter>
//       <Routes>
//                 <Route path='/' element={<HomePage />} />

//                 <Route path="/about" element={<AboutUsPage />} />
//                 <Route path="/products" element={<ProductsPage />} />
//                 <Route path="/support" element={<SupportPage />} />
//                 <Route path='/careers' element={<CareersPage />} />
//                 <Route path="/contact" element={<ContactUsPage />} />

//                 <Route path='*' element={<NoMatch />} />
//             </Routes>
//     {/* <HomePage /> */}
//   </HashRouter>
// ))


// root.render(
//     <ThemeProvider theme={muiTheme}>
//         {inputGlobalStyles}
        
//         <BrowserRouter>

//             <Routes>
//                 <Route path='/' element={<HomePage />} />

//                 <Route path="/about" element={<AboutUsPage />} />
//                 <Route path="/products" element={<ProductsPage />} />
//                 <Route path="/support" element={<SupportPage />} />
//                 <Route path='/careers' element={<CareersPage />} />
//                 <Route path="/contact" element={<ContactUsPage />} />

//                 <Route path='*' element={<NoMatch />} />
//             </Routes>
//         </BrowserRouter>
//     </ThemeProvider>
// )
