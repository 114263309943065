
import styled from 'styled-components';

// import * as Session from 'api/session';
import theme_ from 'theme/Theme';

// import Env from 'env';
import React from 'react';
import { AppBar, Box, Button, IconButton, Menu, MenuItem, SwipeableDrawer, Toolbar } from '@mui/material';
import Env from 'env';
import PageNavBarMobile from './PageNavBarMobile';
// import PageNavBarMobile from './PageNavBarMobile';

import { Link } from 'react-scroll'

import { useTranslation } from 'react-i18next';




const NavContainer = styled.div`
    position: absolute;
    // bottom: 30px;
    top: 45px;
    height: 20px;
    display: inline-block;
    // right: 590px;
    right: 110px;
`;

const NavContainerMobile = styled.div`
    position: absolute;

    top: 45px;
    height: 20px;
    display: inline-block;
    padding-left: 114px;
    right: 0px;
`;




interface PageNavProps {
    current: string;
}

export default function PageNav(props: PageNavProps) {
    const [t] = useTranslation();

    if (Env.isMobile) {
        return <>
            <PageNavBarMobile />
           
        </>;
    }

    return <>

        <NavContainer>
            {/* <Link style={{paddingRight: 8, marginLeft: 30 ,color: 'white', fontSize: 'large', cursor: 'pointer'}} activeClass="active" to="home" spy={true} smooth={true}>Home</Link> */}
            <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="about" spy={true} smooth={true}>{t('ABOUT')}</Link>
            <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="products" spy={true} smooth={true}>{t('PRODUCTS & SERVICES')}</Link>
            <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="support" spy={true} smooth={true}>{t('SUPPORT')}</Link>
            <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="partners" spy={true} smooth={true}>{t('PARTNERS')}</Link>
            {/* <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="careers" spy={true} smooth={true}>{t('CAREERS')}</Link> */}
            <Link style={{ fontFamily: theme_.fontType, paddingRight: 50, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="contact" spy={true} smooth={true}>{t('CONTACT')}</Link>
        </NavContainer>

        {/* <NavContainer>
            <PageNavItem tag='about' title='ABOUT US' href='/about' current={props.current} show={true} />
            <PageNavItem tag='products' title='PRODCUTS & SERVICES' href='/products' current={props.current} show={true} />
            <PageNavItem tag='support' title='SUPPORT' href='/support' current={props.current} show={true} />
            <PageNavItem tag='careers' title='CAREERS' href='/careers' current={props.current} show={true} />
            <PageNavItem tag='contact' title='CONTACT' href='/contact' current={props.current} show={true} />
        </NavContainer> */}
    </>
}




























/*
<NavBarContainer expand="lg" variant="primary" >

<div className="content">
    <ul>
        <li ><a href="/">Home</a></li>
        {Session.instance.isLoggedIn && <li><a href="/scans">Scans</a></li>}

        {Session.instance.isAdmin && <li><a href="/admin">Admin</a></li>}
        {Session.instance.isDeveloper && <li><a href="/dev">Dev</a></li>}

        <li><a href="/contact">Contact Us</a></li>
    </ul>
</div>
</NavBarContainer>
*/
