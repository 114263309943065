const globalFonts = [
    'Roboto',
    // 'Montserrat', 
    '-apple-system', 
    'BlinkMacSystemFont', 
    'Segoe UI', 
    'Oxygen', 
    'Ubuntu', 
    'Cantarell', 
    'Fira Sans', 
    'Droid Sans', 
    'Helvetica Neue', 
    'sans-serif',
];

const codeFonts = [
    'source-code-pro', 
    'Menlo', 
    'Monaco', 
    'Consolas', 
    'Courier New', 
    'monospace',
    'Inter',
    // 'Montserrat'
];


const globalStyles = {
    body: {
        fontFamily: globalFonts.join(','),
        
        fontSmooth: 'auto',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },

    code: {
        fontFamily: codeFonts.join(','),
    },

    ".leaflet-container": {
        width: '100%',
        //height: '100vh',
        height: '100%',
        zIndex: 1,
    }
      
}

export default globalStyles;


