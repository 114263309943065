import React from 'react';
import Env from 'env'


import { Box, Container, Typography } from '@mui/material';
import { Popper } from '@mui/material';
import { Select, InputLabel, InputBase, FormControl, MenuList, MenuItem, SelectChangeEvent } from '@mui/material';

import * as i18n from 'i18n';
// import * as GD from 'data/GlobalDispatch';

import language_ from 'language/languageProcess';
import theme_ from 'theme/Theme';

import { styled as muiStyled } from '@mui/system';
import { selectedLang } from 'pages/home/HomePage';
// import { selectedLang } from "../theme/theme";


const ImageComponent = muiStyled('img')({});




interface Props {
    type: 'button' | 'popper';

    show?: boolean;
    anchorRef?: any;
    anchorId?: string;
};

export default function LangSelector(props: Props) {
    const [currentLang, setCurrentLang] = React.useState(language_.lang);
    const currentLangRef = React.useRef('')

    // console.log('currentLang', currentLang)
    // const th = theme_.homePageHeaderTheme;
    // const thLang = th.langSelector;
    // const [currentLang, setCurrentLang] = React.useState('ru');


    // console.log(currentLang)

    //     React.useEffect(() => {
    //         const langListener = () => {
    //             setCurrentLang(session_.lang);
    //         }

    //         GD.pubsub_.addListener(GD.langListenerId, langListener);

    //         return () => GD.pubsub_.removeListener(GD.langListenerId, langListener);
    //     }, []);

    const handleChange = React.useCallback((event: SelectChangeEvent) => {
        setCurrentLang(event.target.value);
        currentLangRef.current = event.target.value
        // let lang = event.target.value as string;
        selectedLang(currentLangRef.current)
        language_.setLang(currentLangRef.current);
    }, []);

    if (props.type === 'popper') {
        let open = props.show === false ? false : true;
        let anchorEl = open ? document.getElementById(props.anchorId!) : null;

        return <Popper
            open={open}
            anchorEl={anchorEl}
            transition
        >
            <MenuList>
                {i18n.supportedLangs_.map(lang =>
                    <MenuItem key={lang.lang} value={lang.lang}>{lang.label}</MenuItem>
                )}
            </MenuList>
        </Popper>;
    }
    // console.log(thLang)

    // if (!thLang.show)
    //     return <></>;

    // return <Box sx={thLang.sxProps()}>


    if (Env.isMobile) {
        return <Box sx={{ position: 'absolute', right: 140, top: 15}}>
            <FormControl sx={{
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            }}>
                <Select
                    value={currentLang}
                    onChange={handleChange}
                    input={<SelectorInput />}
                    sx={{
                        // position: thLang.position,
                        width: 120,
                        left: 0,
                        top: 20,
                        right: 0,
                        bottom: 0,
                        borderRadius: 0,
                        zIndex: 1,
                        color: 'white'
                    }}
                >
                    {i18n.supportedLangs_.map(lang =>
                        <MenuItem key={lang.lang} value={lang.lang} sx={{
                        }}><ImageComponent sx={{marginRight: 1, width: 35 }} src={lang.flag} ></ImageComponent>{lang.label} 
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

        </Box>

    }


    return <Box sx={{ position: 'absolute', right: 25, top: 18 }}>
        <FormControl sx={{
            // position: thLang.position,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            //borderColor: theme_.pageTextColor,
        }}>
            <Select
                value={currentLang}
                onChange={handleChange}
                input={<SelectorInput />}
                sx={{
                    // position: thLang.position,
                    width: 106,
                    left: 0,
                    top: 21.5,
                    right: 0,
                    bottom: 0,
                    borderRadius: 0,
                    zIndex: 1,
                    color: 'white',

 
                }}
            >
                {/* background-color: transparent;
    border: 0px solid;
    height: 20px;
    width: 160px;
    color: #CCC; */}
                {i18n.supportedLangs_.map(lang =>
                    <MenuItem key={lang.lang} value={lang.lang} sx={{
                    }}><ImageComponent sx={{ marginRight: 1, width: 25 }} src={lang.flag} ></ImageComponent>{lang.label}
                    </MenuItem>
                )}
            </Select>
        </FormControl>

    </Box>

}

const SelectorInput = muiStyled(InputBase)(({ theme }) => ({
    color: theme_.pageTextColor,
    '& .MuiInputBase-input': {
        // border: '1px solid #ced4da',
        border: '0px solid',
        paddingLeft: 8,
        display: 'flex'
    },

    //'& .MuiSelectIcon': {
    '& svg': {
        color: 'white',
    }
}));
