import React from 'react'
import styled from 'styled-components/macro';

import theme_ from '../theme/Theme';




export const PageHeaderHeightUnits = 'rem';


export const PageContainerDiv = styled.div`
    // position: absolute;

    // padding: 15px;
    top: 0;
    bottom: 0;
//    min-width: ${theme_.pageMinWidth}px;
`;

interface PageContainerProps {
    children: React.ReactNode;
};


export function PageContainer(props: PageContainerProps) {
// export function PageContainer({children}) {
    return <PageContainerDiv>
        {props.children}
    </PageContainerDiv>
}


export const PageHeaderContainer = styled.div`
    position: absolute;
    width: 100%;
    min-width: ${theme_.pageMinWidth}px;
    left: 0;
    right: 0;
    height: ${theme_.pageHeaderHeight}px;
`;


export const PageDialogContents = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const ContactUsPageDialog= styled.div`
    display: flex;
    // align-items: center;
    justify-content: center;
    // background-color: #21c4be;
    // background-color: #cbb77e;
    background-color: #e8a412;
    padding: 5px;
    width: 800px;
    height: auto;
    margin-top: 150px;
`

export const JoinPageDialog = styled.div`
    display: flex;
    // align-items: center;
    justify-content: center;
    // background-color: #cbb77e;
    background-color: #e8a412;
    padding: 5px;
    width: 890px;
    height: auto;
    margin-top: 50px;
`

export const LoginPageDialog = styled.div`
    display: flex;
    // align-items: center;
    justify-content: center;
    // background-color: #cbb77e;
    background-color: #e8a412;
    padding: 5px;
    width: 840px;
    height: auto;
    margin-top: 160px;
`

export const ForgotPageDialog = styled.div`
    display: flex;
    // align-items: center;
    justify-content: center;
    // background-color: #cbb77e;
    background-color: #e8a412;
    padding: 5px;
    width: 410px;
    height: auto;
    margin-top: 160px;
`

