import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import Env from 'env'

import { styled as muiStyled } from '@mui/system';

import { createTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";


const ImageComponent = muiStyled('img')({});


const theme = createTheme({
    typography: {
        fontFamily: 'Inter',

    }
});

export default function AboutUsPage() {

    const [t] = useTranslation();


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 48, display: 'flex' }}>

                <Box sx={{ width: 'auto', marginTop: 20 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <Divider sx={{ width: '40px', borderBottomWidth: 5, backgroundColor: 'black', marginLeft: 5 }}></Divider>
                        <Typography sx={{ fontFamily: theme_.fontType, color: 'black', fontSize: 35, marginLeft: 3 }}>{t('About us')}</Typography>
                    </Box>

                    <Typography sx={{ color: 'black', fontSize: 20, marginLeft: 5, marginTop: 3 }}>
                    {t( 'Our team of Ph.D. holders, engineers, statisticians, lawyers, and business management consultants has the ability to translate highly technical information into meaningful insights for better decision making. Our extensive domain expertise and meticulous approach help us provide deeper levels of insights than others in the industry. We’re more than just an IT research and developer company. Our engineers have all the skills to cover your hardware, software and networking needs. We are ready to provide optimal and thoughtful solutions according to customer requirements. Our goal is put together a full-scale electronic device. We will take you great product conceptualization to trial manufacturing from scratch to mass production.')}

                    </Typography>
                </Box>

                <Box sx={{marginRight: 5}}>
                    <ImageComponent sx={{ marginTop: theme_.aboutUsImageMarginTop, width: 250, height: 'auto', marginLeft: 5 }} src="images/about-us-image.png"></ImageComponent>
                </Box>

            </Box>
        </>
    }

    return <>

        <Box sx={{ width: '100%', marginTop: 35, display: 'flex' }}>

            <Box sx={{ width: 1000, marginTop: 33 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <Divider sx={{ width: '40px', borderBottomWidth: 5, backgroundColor: 'black', marginLeft: 15 }}></Divider>
                    <Typography sx={{fontFamily: 'Inter', color: 'black', fontSize: 35, marginLeft: 3 }}>{t('About us')}</Typography>
                </Box>

                <Typography sx={{ color: 'black', fontSize: 20, marginLeft: 15, marginTop: 3 }}>
                {t( 'Our team of Ph.D. holders, engineers, statisticians, lawyers, and business management consultants has the ability to translate highly technical information into meaningful insights for better decision making. Our extensive domain expertise and meticulous approach help us provide deeper levels of insights than others in the industry. We’re more than just an IT research and developer company. Our engineers have all the skills to cover your hardware, software and networking needs. We are ready to provide optimal and thoughtful solutions according to customer requirements. Our goal is put together a full-scale electronic device. We will take you great product conceptualization to trial manufacturing from scratch to mass production.')}
                </Typography>
            </Box>

            <Box>
                <ImageComponent sx={{ marginTop: 25, width: 450, height: 'auto', marginLeft: 25 }} src="images/about-us-image.png"></ImageComponent>
            </Box>

        </Box>
    </>

}

