/*eslint node/no-process-env: "off"*/

import device from 'current-device'

// import * as Path from 'lib/path'
// import {assertNotEmpty} from 'lib/assert'

export default class Env {
    //
    // Development/production modes
    //
    static readonly nodeEnv = process.env.NODE_ENV
    static readonly isDev = process.env.NODE_ENV === 'development'
    static readonly isProd = !Env.isDev

    //
    // Convenience functions
    //
    static getOption(name: string, def: string): string {
        let val = process.env[name]
        return val ?? def
    }

    // static requireOption(optName: string): string {
    //     let val = process.env[optName]
    //     assertNotEmpty(val, `.env missing: ${optName}`)
    //     return val!
    // }

    // static getAppOption(name: string, def: string): string {
    //     return Env.getOption('REACT_APP_' + name, def)
    // }

    // static requireAppOption(optName: string): string {
    //     return Env.requireOption('REACT_APP_' + optName)
    // }

    // //
    // // Assets Locations
    // //
    // static readonly publicUrl = Env.getOption('PUBLIC_URL', '/')
    // static readonly publicUrlImages = Path.makeFilePathRoot(Env.publicUrl, 'images')
    // static makePublicImageUrl(...comps: string[]): string {
    //     return Path.makeFilePathRootArr(Env.publicUrlImages, comps)
    // }

    //
    // Device information
    //
    static readonly isMobile = device.mobile()
    static readonly isDesktop = device.desktop()
    static readonly isTablet = device.tablet()
}
