import React from "react";
import Env from 'env'

import { Box, Divider, Typography } from "@mui/material";

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";


const ImageComponent = muiStyled('img')({});



export default function PartnersPage() {

    const [t] = useTranslation();


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 35 }}>

                <Box sx={{ display: 'grid' }}>

                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 35, marginTop: 3, width: theme_.supportPageTitleWidth, marginRight: 'auto', marginLeft: 'auto' }}>{t('PARTNERS')}</Typography>
                    <Divider sx={{ width: '600px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider>

                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: 17 }}>

                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 15 }}> */}


                    <Box sx={{ display: 'grid', padding: 3 }}>
                        <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Aspeed.png"></ImageComponent>
                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 2.5 }}>{t('IT Products & Services')}</Typography> */}
                    </Box>

                    <Box sx={{ display: 'grid', padding: 3 }}>
                        {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Intel_logo_PNG1.png"></ImageComponent> */}
                        <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/renesas_technology.png"></ImageComponent>

                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Telecommunication')}</Typography> */}

                    </Box>

                    {/* <Box sx={{ display: 'grid', marginTop: 3, padding: 3 }}> */}
                    <Box sx={{ display: 'grid', marginTop: -2, padding: 3 }}>
                        {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/iMicrochip_logo.png"></ImageComponent> */}
                        <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Rohde&Schwarz.png"></ImageComponent>

                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Server & Networking')}</Typography> */}

                    </Box>


                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: 17 }}>

                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 25 }}> */}


                    <Box sx={{ display: 'grid', padding: 2 }}>
                        {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/renesas_technology.png"></ImageComponent> */}
                        <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Intel_logo_PNG1.png"></ImageComponent>

                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}> {t('Tech Start-Ups')}</Typography> */}
                    </Box>

                    <Box sx={{ display: 'grid', padding: 2 }}>
                        {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Rohde&Schwarz.png"></ImageComponent> */}
                        <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/iMicrochip_logo.png"></ImageComponent>

                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Research & Training')}</Typography> */}

                    </Box>



                </Box>

            </Box>

        </>
    }


    return <>

        <Box sx={{ width: '100%', marginTop: 35 }}>

            <Box sx={{ display: 'grid' }}>

                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 35, marginTop: 4 }}>{t('PARTNERS')}</Typography>
                <Divider sx={{ width: '400px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '65%', marginLeft: 'auto', marginRight: 'auto', marginTop: 10 }}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 15 }}> */}


                <Box sx={{ display: 'grid' }}>
                    <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Aspeed.png"></ImageComponent>
                    {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3, width: 300 }}>{t('IT Products & Services')}</Typography> */}
                </Box>

                <Box sx={{ display: 'grid' }}>
                    {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Intel_logo_PNG1.png"></ImageComponent> */}
                    <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop + 50, height: 'auto' }} src="images/renesas_technology.png"></ImageComponent>

                    {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Telecommunication')}</Typography> */}

                </Box>

                <Box sx={{ display: 'grid' }}>
                    {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/iMicrochip_logo.png"></ImageComponent> */}
                    <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop + 50, height: 'auto' }} src="images/Rohde&Schwarz.png"></ImageComponent>

                    {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 6 }}>{t('Server & Networking')}</Typography> */}

                </Box>


                {/* </Box> */}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '65%', marginLeft: 'auto', marginRight: 'auto', marginTop: 17 }}>


                {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 25 }}> */}


                <Box sx={{ display: 'grid' }}>
                    {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop + 50, height: 'auto' }} src="images/renesas_technology.png"></ImageComponent> */}
                    <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/Intel_logo_PNG1.png"></ImageComponent>
                    {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}> {t('Tech Start-Ups')}</Typography> */}
                </Box>

                <Box sx={{ display: 'grid' }}>
                    {/* <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop + 50, height: 'auto' }} src="images/Rohde&Schwarz.png"></ImageComponent> */}
                    <ImageComponent sx={{ justifySelf: 'center', width: theme_.partnersLogoWidthDesktop, height: 'auto' }} src="images/iMicrochip_logo.png"></ImageComponent>

                    {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Research & Training')}</Typography> */}

                </Box>



            </Box>

        </Box>

    </>

}

