import React from 'react'

import { Box, Drawer, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Env from 'env'

import { Link } from 'react-scroll'
import theme_ from 'theme/Theme'


interface MenuProps {
    open: boolean
    onSelected: (key: string) => void
    onClose: () => void
}

export default function PageMainMenu(props: MenuProps) {
    const navigate = useNavigate()

    const [t] = useTranslation()

    const [showLang, setShowLang] = React.useState(false)
    //const langItemRef = React.useRef(null);
    const langItemRef = React.createRef()

    const onClose = React.useCallback(() => {
        setShowLang(false)
        props.onClose()
    }, [])

    const onItemSelected = React.useCallback((key: string) => {
        setShowLang(false)

        switch (key) {
            case 'logout':
                props.onSelected(key)

                break

            case 'lang':
                setShowLang(true)
                break

            default:
                props.onSelected(key)
                //setShowLang(false);
                break
        }
    }, [])

    const drawerWidth = Env.isMobile ? 250 : 300


    return (
        <Drawer open={props.open} anchor='right' onClose={onClose}>
            <Box sx={{ width: drawerWidth, display: 'grid', justifyItems: 'center' }}>
                <List >
                    <PageMenuItem
                        itemKey='about'
                        label='About'
                        onSelected={(key) => onItemSelected(key)}
                        href='/about'
                    />
                </List>

                {Env.isMobile && (
                    <>
                        {/* <Divider /> */}
                        <PageMenuItem
                            itemKey='products'
                            label='Products & Services'
                            onSelected={(key) => onItemSelected(key)}
                            href='/products'
                        />
                        <PageMenuItem
                            itemKey='support'
                            label='Support'
                            onSelected={(key) => onItemSelected(key)}
                            href='/support'
                        />

                        <PageMenuItem
                            itemKey='partners'
                            label='Partners'
                            onSelected={(key) => onItemSelected(key)}
                            href='/partners'
                        />
                        
                         <PageMenuItem
                            itemKey='contact'
                            label='Contact'
                            onSelected={(key) => onItemSelected(key)}
                            href='/contact'
                        />

                    </>
                )}
            </Box>
        </Drawer>
    )
}

interface ItemProps {
    itemKey: string
    label: string
    //menu: MenuProps;
    onSelected: (key: string) => void
    href?: string
    anchorRef?: any
    itemId?: string
}

function PageMenuItem(props: ItemProps) {
    const navigate = useNavigate()

    const [t] = useTranslation()

    const [label, setLabel] = React.useState(t(props.label) as string)

    const onSelected = React.useCallback(() => {
        props.onSelected(props.itemKey)

        // if (props.href) {
        //     navigate(props.href)
        // }
    }, [])

    return (
        <>
            <Box sx={{display: 'flex'}}>

                <Link onClick={onSelected} style={{ fontFamily: theme_.fontType , padding: 8, marginLeft: 2, color: 'black', fontSize: 22, cursor: 'pointer', textAlign: 'center' }} to={props.itemKey} spy={true} smooth={true}>{t(label)}</Link>
               
            </Box>
        </>
    //     <ListItem id={props.itemId} ref={props.anchorRef} button key={props.itemKey}>
    //     <ListItemButton onClick={onSelected}>
    //         <ListItemText primaryTypographyProps={{ fontSize: 22 }}>{label}</ListItemText>

    //     </ListItemButton>
    // </ListItem>
    )
}
