
export class SupportedLangInfo {
    lang: string;

    label: string;

    flag: string

    constructor(lang: string, label: string, flag: string) {
        this.lang = lang;
        this.label = label;
        this.flag = flag
    }
}

export const supportedLangs_ = new Array<SupportedLangInfo>();

supportedLangs_.push(new SupportedLangInfo('en', 'ENG', 'images/flags/us.svg'));
// supportedLangs_.push(new SupportedLangInfo('ru', 'РУС', 'images/flags/ru.svg'));
supportedLangs_.push(new SupportedLangInfo('am', 'ՀԱՅ', 'images/flags/am.svg' ));

