import { createTheme } from '@mui/material/styles';
import theme_ from './Theme';

const muiTheme = createTheme({

    typography: {
        h1: {
            fontSize: '3rem',
        },

        h2: {
            fontSize: '2.6rem',
        },

        h3: {
            fontSize: '2.2rem',
        },

        h4: {
            fontSize: '1.6rem',
        },
    },

    // components: {

    //     MuiTableRow: {
    //         styleOverrides: {
    //             root: {
    //                 backgroundColor: '#f7e0b8',
    //                 "&:nth-of-type(2n)": { 
    //                     backgroundColor: '#fcf4e6',
    //                 }
    //             }
    //         }
    //     },



    // },

});

export default muiTheme;
