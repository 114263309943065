import React from "react";

import HtmlHeader from "../ui/HtmlHeader";

import * as Page from "./PageContainer";
import { DomSub } from "../ui/DomSub";

// import AuthHeader from "./AuthHeader";
// import PageBanner from "./PageBanner";
import PageNav from "./PageNavBar";
import PageLogo from "./PageLogo";
import PageNavBarMobile from "./PageNavBarMobile";

import LangSelector from './LangSelector';


// import Env from "env";


export class PageHeaderState {

    domSub = new DomSub();

    release() {
        this.domSub.release();
    }
};


interface PageHeaderProps {
    current?: string;
    title?: string;
}


// export default function PageHeader(props: PageHeaderProps) {
//     if (Env.isMobile) {
//         return <Page.PageHeaderContainer>
//                 <HtmlHeader title={props.title ?? 'Bug Path Finder'} />
//                 <PageLogo/>
//                 <PageBanner/>
//                 <AuthHeader/>
//                 {/* <PageNav current={props.current ?? 'home'}/> */}
//             </Page.PageHeaderContainer>;
//     } else{
//             return <Page.PageHeaderContainer>
//                 <HtmlHeader title={props.title ?? 'Bug Path Finder'} />
//                 <PageLogo/>
//                 <PageBanner/>
//                 <AuthHeader/>
//                 <PageNav current={props.current ?? 'home'}/>
//             </Page.PageHeaderContainer>;
//     }
    
// }

export default function PageHeader(props: PageHeaderProps) {
    
    
    return <Page.PageHeaderContainer>
        <HtmlHeader title={props.title ?? 'Tsiran'} />
        <PageLogo/>
        {/* <PageBanner/> */}
        <LangSelector type='button'/>

        <PageNav current={props.current ?? 'home'}/>        
    </Page.PageHeaderContainer>;
}

