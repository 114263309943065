import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import Env from 'env'


import PageMainMenu from './PageMainMenu'



export default function PageNavBarMobile() {
    const navigate = useNavigate()

    const [sessionUpdated, setSessionUpdated] = React.useState(false)

    const [openDrawer, setOpenDrawer] = React.useState(false)




    const onMenuItem = React.useCallback((key: string) => {
        setOpenDrawer(false)
    }, [])



    return (
        <>

            <Button onClick={() => setOpenDrawer(!openDrawer)} sx={{position: 'absolute', right: 40, top: 15, color: 'white'}}>
                <MenuIcon
                    sx={{
                        //color: theme_.navButtonTextColor,
                        width: 60,
                        height: 60,
                    }}
                />
            </Button>


            <PageMainMenu
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onSelected={onMenuItem}
            />
        </>
    )
}
