import React from "react";
import Env from 'env'

import { styled as muiStyled } from '@mui/system';
import { Box, Divider, Grid, Typography } from "@mui/material";

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import session_ from "../language/languageProcess";


const ImageComponent = muiStyled('img')({});


const styles = {
    paperContainer: {
        // height: 800,
        backgroundImage: `url("images/it-network-image.svg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        // width: `calc(100vw + 48px)`,
        // margin: -24,
        // padding: 24,
    }
};


export default function ProductsPage() {

    const [t] = useTranslation();


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 40 }}>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                    {/* <Divider sx={{ width: '40px', borderBottomWidth: 8, backgroundColor: 'black', marginLeft: -3 }}></Divider> */}
                    <Typography sx={{ textAlign: 'center', color: 'white', fontSize: 45 }}>{t('PRODUCTS & SERVICES')}  </Typography>
                    <ImageComponent sx={{ position: 'absolute', width: '100%', height: 'auto', zIndex: -1 }} src="images/products-image.svg"></ImageComponent>
                </Box>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ justifyContent: 'center' }}>
                    <Grid item xs={6}>
                        <Box sx={{
                            // width: 350,
                            // height: 350,
                            // borderRadius: 5,
                            // boxShadow: 3,
                            // backgroundColor: 'white',
                            marginTop: 15,
                            // display: 'flex',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            marginLeft: 4,
                            // backgroundImage: `url("images/it-network-image.svg")`,
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'right',
                            // backgroundSize: "120px"
                        }}>
                            <Accordion sx={{
                                width: 350,
                                // height: 350,
                                minHeight: 650,

                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                // marginTop: 15,
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                                // marginLeft: 4,
                                backgroundImage: `url("images/it-network-image.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    {/* <Typography sx={{ textAlign: 'center', fontSize: 25 }}>{t('IT Network Solution')} </Typography> */}
                                    <Box sx={{ textAlign: 'center', width: 300 }}>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('Electronic Engineering Services & Product Development')} </Typography>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 20, mt: 2 }}>
                                            {t(`Tsiran-It PCB has the tools and knowledge to design your PCB no matter how large or small. We use the industry’s top tier design tools and are driven to be the best. High speed, multi layer PCB designs – Bus routing, differential pairs, matched lengths.`)}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1, mt: 2 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Electronic hardware design: analog & digital')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('PCB Design')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('BOM creation')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Manufacturing data creation')}
                                        </Typography>
                                    </Box>

                                    {session_.lang !== 'am' &&
                                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                            <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                            <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                                {t('Firmware development')}
                                            </Typography>
                                        </Box>
                                    }

                                    {session_.lang !== 'am' &&
                                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                            <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                            <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                                {t('Software development')}
                                            </Typography>
                                        </Box>
                                    }

                                    {session_.lang !== 'am' &&
                                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                            <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                            <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                                {t('Prototype building')}
                                            </Typography>
                                        </Box>
                                    }

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Bench testing and debug')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Full turnkey production of finished product')}
                                        </Typography>
                                    </Box>
                                    {/* </Typography> */}
                                </AccordionDetails>

                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            // width: 350,
                            // height: 350,
                            // borderRadius: 5,
                            // boxShadow: 3,
                            // backgroundColor: 'white',
                            marginTop: 15,
                            // display: 'flex',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            // backgroundImage: `url("images/embedded-image.svg")`,
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'right',
                            // backgroundSize: "120px"
                        }}>

                            <Accordion sx={{
                                width: 350,
                                // height: 350,
                                minHeight: 650,

                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                // marginTop: 15,
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                                // marginLeft: 4,
                                backgroundImage: `url("images/it-network-image.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        {/* <Typography sx={{ textAlign: 'center', padding: 8, fontSize: 25 }}>{t('Embedded Systems Design & Development')} </Typography> */}
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('RF Engineering & PCB Design')} </Typography>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 20, mt: 2 }}>
                                            {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Packet radio transmitters & receivers')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Frequency up converters up to 18 GHz (two-step and three-step)')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Frequency down converters up to 18 GHz (two-step and three-step)')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Interference protected passive GPS antennas')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Antenna design up to12 GHz')}
                                        </Typography>
                                    </Box>

                                    {session_.lang !== 'am' &&

                                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                            <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                            <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                                {t('Antenna tuning and impedance matching')}
                                            </Typography>
                                        </Box>
                                    }

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Low noise amplifiers')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Base amplifiers')}
                                        </Typography>
                                    </Box>

                                    {session_.lang !== 'am' &&
                                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                            <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                            <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                                {t('Prototype building')}
                                            </Typography>
                                        </Box>
                                    }

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Bench testing and debug')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Full turnkey production of finished product')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Vector modulators')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Phase shifter')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('High frequency switches')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Microstrip filters')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('RF power combiners/dividers')}
                                        </Typography>
                                    </Box>

                                </AccordionDetails>

                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>

                        <Box sx={{
                            // width: 350,
                            // height: 350,
                            // borderRadius: 5,
                            // boxShadow: 3,
                            // backgroundColor: 'white',
                            marginTop: 15,
                            // display: 'flex',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            // marginLeft: 4,
                            // backgroundImage: `url("images/measurement-image.svg")`,
                            // backgroundRepeat: 'no-repeat',
                            // backgroundPosition: 'right',
                            // backgroundSize: "120px"

                        }}>
                            <Accordion sx={{
                                width: 350,
                                // height: 350,
                                minHeight: 650,

                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                // marginTop: 15,
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                                // marginLeft: 4,
                                backgroundImage: `url("images/it-network-image.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>
                                        {/* <Typography sx={{ textAlign: 'center', padding: 7, fontSize: 25 }}>{t('Measurement and Control of RF systems')} </Typography> */}

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('IoT systems design')} </Typography>
                                    </Box>
                                </AccordionSummary>



                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('LoRAWAN Wireless systems')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Smart home nodes design')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Soil conditions monitoring')}
                                        </Typography>
                                    </Box>

                                </AccordionDetails>

                            </Accordion>
                        </Box>

                    </Grid>
                    {/* <Grid item xs={6}>
                        <Box sx={{
                            width: 350,
                            height: 350,
                            borderRadius: 5,
                            boxShadow: 3,
                            backgroundColor: 'white',
                            marginTop: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundImage: `url("images/telecommunication-image.svg")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right',
                            backgroundSize: "105px"
                        }}>

                            <Typography sx={{ textAlign: 'center', padding: 7, fontSize: 25 }}> {t('Telecommunication Equipment Design & Development')} </Typography>

                        </Box>
                    </Grid> */}
                </Grid>

            </Box>
        </>
    }


    return <>

        <Box sx={{ width: '100%', marginTop: 10 }}>


            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                {/* <Divider sx={{ width: '40px', borderBottomWidth: 5, backgroundColor: '#fd8f20', marginLeft: -3, marginTop: 30 }}></Divider> */}
                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', color: 'white', fontSize: 35, marginTop: 30 }}>{t('PRODUCTS & SERVICES')}  </Typography>
                <ImageComponent sx={{ position: 'absolute', width: '100%', height: 'auto', zIndex: -1, marginTop: 30 }} src="images/products-image.svg"></ImageComponent>
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>

                {/* <Box sx={{ */}
                <Box sx={{
                    // width: 250,
                    // // height: 250,
                    // minHeight: 250,
                    // borderRadius: 5,
                    // boxShadow: 3,
                    // backgroundColor: 'white',
                    marginTop: 20,
                    // display: 'block',
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    // backgroundImage: `url("images/it-network-image.svg")`,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'right',
                    // backgroundSize: "102px"


                }}>
                    <Accordion sx={{
                        width: 350,
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // marginTop: 20,
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        backgroundImage: `url("images/it-network-image.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250,
                            }}
                        >
                            {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18 }}> {t('IT Network Solution')} </Typography> */}
                            <Box sx={{ textAlign: 'center', width: 300 }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> {t('Electronic Engineering Services & Product Development')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {t(`Tsiran-It PCB has the tools and knowledge to design your PCB no matter how large or small. We use the industry’s top tier design tools and are driven to be the best. High speed, multi layer PCB designs – Bus routing, differential pairs, matched lengths.`)}
                                </Typography>
                            </Box>
                            {/* <ImageComponent src="images/it-network-image.svg"></ImageComponent> */}
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Electronic hardware design: analog & digital')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('PCB Design')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('BOM creation')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Manufacturing data creation')}
                                </Typography>
                            </Box>

                            {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Firmware development')}
                                    </Typography>
                                </Box>
                            }

                            {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Software development')}
                                    </Typography>
                                </Box>
                            }

                            {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Prototype building')}
                                    </Typography>
                                </Box>
                            }

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Bench testing and debug')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Full turnkey production of finished product')}
                                </Typography>
                            </Box>
                            {/* </Typography> */}
                        </AccordionDetails>


                        {/* </Box> */}
                    </Accordion>

                </Box>



                <Box sx={{

                    marginTop: 20,
                }}>

                    <Accordion sx={{
                        width: 350,
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        backgroundImage: `url("images/it-network-image.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px",


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250,
                            }}
                        >
                            <Box sx={{ textAlign: 'center', width: 300 }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> {t('RF Engineering & PCB Design')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)}
                                </Typography>

                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 8, fontSize: 18 }}> {t('Embedded Systems Design & Development')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Packet radio transmitters & receivers')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3, fontSize: session_.lang === 'am' ? 14 : null }}>
                                    {t('Frequency up converters up to 18 GHz (two-step and three-step)')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3, fontSize: session_.lang === 'am' ? 14 : null }}>
                                    {t('Frequency down converters up to 18 GHz (two-step and three-step)')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Interference protected passive GPS antennas')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Antenna design up to12 GHz')}
                                </Typography>
                            </Box>

                            {session_.lang !== 'am' && <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Antenna tuning and impedance matching')}
                                </Typography>
                            </Box>
                            }
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Low noise amplifiers')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Base amplifiers')}
                                </Typography>
                            </Box>

                            {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Prototype building')}
                                    </Typography>
                                </Box>
                            }

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Bench testing and debug')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Full turnkey production of finished product')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Vector modulators')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Phase shifter')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('High frequency switches')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Microstrip filters')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('RF power combiners/dividers')}
                                </Typography>
                            </Box>

                        </AccordionDetails>



                    </Accordion>
                </Box>
                {/* <ImageComponent sx={{}} src="images/embedded-image.svg"></ImageComponent> */}

                <Box sx={{
                    // width: 250,
                    // height: 250,
                    // borderRadius: 5,
                    // boxShadow: 3,
                    // backgroundColor: 'white',
                    marginTop: 20,
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    // backgroundImage: `url("images/measurement-image.svg")`,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'right',
                    // backgroundSize: "102px"

                }}>
                    <Accordion sx={{
                        width: 350,
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        backgroundImage: `url("images/it-network-image.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250,
                            }}
                        >
                            <Box sx={{ textAlign: 'center', width: 300 }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, ml: 2, fontWeight: 'bold' }}> {t('IoT systems design')} </Typography>


                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 7, fontSize: 18 }}>{t('Measurement and Control of RF systems')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('LoRAWAN Wireless systems')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Smart home nodes design')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Soil conditions monitoring')}
                                </Typography>
                            </Box>

                        </AccordionDetails>


                    </Accordion>
                </Box>






                {/* <Box sx={{
                    width: 250,
                    height: 250,
                    borderRadius: 5,
                    boxShadow: 3,
                    backgroundColor: 'white',
                    marginTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: `url("images/telecommunication-image.svg")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                    backgroundSize: "85px"

                }}>

                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 7, fontSize: 18 }}> {t('Telecommunication Equipment Design & Development')} </Typography>

                </Box> */}

            </Box>


        </Box >
    </>

}

