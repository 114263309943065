import React from "react";
import Env from 'env'

import { Box, Divider, Typography } from "@mui/material";

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";


const ImageComponent = muiStyled('img')({});



export default function SupportPage() {

    const [t] = useTranslation();


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 35 }}>

                <Box sx={{ display: 'grid' }}>

                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 35, marginTop: 3, width: theme_.supportPageTitleWidth, marginRight: 'auto', marginLeft: 'auto' }}>{t('KEY SECTOR WE SUPPORT')}</Typography>
                    <Divider sx={{ width: '600px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider>

                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: 17 }}>

                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 15 }}> */}


                    <Box sx={{ display: 'grid', padding: 3 }}>
                        <ImageComponent sx={{ justifySelf: 'center' }} src="images/it-servises.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 2.5 }}>{t('IT Products & Services')}</Typography>
                    </Box>

                    <Box sx={{ display: 'grid', padding: 3 }}>
                        <ImageComponent sx={{ justifySelf: 'center' }} src="images/it-servises-telecomunication.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Telecommunication')}</Typography>

                    </Box>

                    <Box sx={{ display: 'grid', marginTop: 3, padding: 3 }}>
                        <ImageComponent sx={{ justifySelf: 'center' }} src="images/it-servises-networking.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Server & Networking')}</Typography>

                    </Box>


                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: 17 }}>

                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 25 }}> */}


                    <Box sx={{ display: 'grid', padding: 2 }}>
                        <ImageComponent sx={{ justifySelf: 'center' }} src="images/it-servises-tech-start-ups.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}> {t('Tech Start-Ups')}</Typography>
                    </Box>

                    <Box sx={{ display: 'grid', padding: 2 }}>
                        <ImageComponent sx={{ justifySelf: 'center' }} src="images/it-servises-research-training.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Research & Training')}</Typography>

                    </Box>



                </Box>

            </Box>

        </>
    }


    return <>

        <Box sx={{ width: '100%', marginTop: 35 }}>

            <Box sx={{ display: 'grid' }}>

                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 35, marginTop: 4 }}>{t('KEY SECTOR WE SUPPORT')}</Typography>
                <Divider sx={{ width: '1000px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '65%', marginLeft: 'auto', marginRight: 'auto', marginTop: 14, '@media screen and (max-width: 1600px)' : { marginTop: 10,}}}>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 15 }}> */}


                <Box sx={{ display: 'grid' }}>
                    <ImageComponent sx={{ justifySelf: 'center', '@media screen and (max-width: 1600px)': { width: 150 } }} src="images/it-servises.svg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3, width: 300 }}>{t('IT Products & Services')}</Typography>
                </Box>

                <Box sx={{ display: 'grid' }}>
                    <ImageComponent sx={{ justifySelf: 'center', '@media screen and (max-width: 1600px)': { width: 110 } }} src="images/it-servises-telecomunication.svg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Telecommunication')}</Typography>

                </Box>

                <Box sx={{ display: 'grid' }}>
                    <ImageComponent sx={{ justifySelf: 'center', mt: 2, '@media screen and (max-width: 1600px)': { width: 140, mt: 0.5 } }} src="images/it-servises-networking.svg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 4.3, '@media screen and (max-width: 1600px)': { marginTop: 3 }  }}>{t('Server & Networking')}</Typography>

                </Box>


                {/* </Box> */}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '65%', marginLeft: 'auto', marginRight: 'auto', marginTop: 14, '@media screen and (max-width: 1600px)' : { marginTop: 10,} }}>


                {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: 25 }}> */}


                <Box sx={{ display: 'grid' }}>
                    <ImageComponent sx={{ justifySelf: 'center', '@media screen and (max-width: 1600px)': { width: 130 } }} src="images/it-servises-tech-start-ups.svg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}> {t('Tech Start-Ups')}</Typography>
                </Box>

                <Box sx={{ display: 'grid' }}>
                    <ImageComponent sx={{ justifySelf: 'center', '@media screen and (max-width: 1600px)': { width: 130 } }} src="images/it-servises-research-training.svg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 23, marginTop: 3 }}>{t('Research & Training')}</Typography>

                </Box>



            </Box>

        </Box>

    </>

}

