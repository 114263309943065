import React from "react";


import styled from 'styled-components';

import theme_ from 'theme/Theme';
import { PageContainer } from "components/PageContainer";

import PageHeader from "./PageHeader";


interface PageContentsProps {
    type?: 'data';  // Contents type

    title?: string;
    current?: string;

    children: React.ReactNode;
};

const backgroundCornerSize = 150;
const backgroundMargin = 10;
const contentsPadding = 100;
const mainPaperSize = 2100;
const PaperHight = 50;

const paperTopMargin = 10;
const paperHorzMargin = 30;
const paperBottomMargin = 30;

const PageContentsDiv = styled.div`
    position: absolute;

    width: 100%;
    min-width: ${theme_.pageMinWidth}px;

    top: ${theme_.pageHeaderHeight}px;
    bottom: 0;
    left: 0;

`;




export const PageContentsId = 'PageContentsId';



export default function PageContents(props: PageContentsProps) {
    // if (props.type === 'data') {
    //     return <PageContainer>
    //         <PageHeader title={props.title} current={props.current} />
    //         <ContentsContainer>
    //             <ContentsData>{props.children}</ContentsData>
    //         </ContentsContainer>
    //     </PageContainer>;
    // }
    return <PageContainer>
        {/* <PageContentPaperImg src='images/paperContainer.svg' /> */}

        <PageHeader title={props.title} current={props.current} />



        <PageContentsDiv id={PageContentsId}>
            {props.children}
        </PageContentsDiv>
    </PageContainer>;
}


// const ContentsContainer = styled.div({
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     top: theme_.pageHeaderHeight,
//     bottom: 0,
//     paddingLeft: theme_.pageHorzMargin,
//     paddingRight: theme_.pageHorzMargin,
//     paddingTop: theme_.pageTopMargin,
//     paddingBottom: theme_.pageBottomMargin,
// });


// const ContentsData = styled.div({
//     width: '100%',
//     height: '100%',
//     backgroundColor: theme_.pageBackgroundColor,
//     // ...theme_.cssDialogBorder(),
// });
