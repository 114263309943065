import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import HttpApi from 'i18next-http-backend';

import { langDefault } from './i18n_lib';
import { supportedLangs_ } from './i18n_status';

export * from 'i18next';

//console.debug(supportedLangs_.map(lng => lng.lang));

i18next
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpApi)

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)

    // connect with React
    .use(initReactI18next)

    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //debug: true,
    
        //lng: 'en',
        fallbackLng: langDefault,
        //supportedLngs: ['en', 'ru'],
        supportedLngs: supportedLangs_.map(lng => lng.lang),

        defaultNS: 'translation',
        ns: 'translation',
    
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        react: {
            useSuspense: false,
        },

        backend: {
            loadPath: '/translations/{{lng}}/{{ns}}.json',
        },        
    });
    
//export const i18n;

//export default i18n;
