import * as i18n from 'i18n';
import * as LS from 'lib/preferences';



export class Language {

    lang: string;  // Selected user language

    constructor() {

        this.lang = i18n.langDefault;
        
    }


    private startProcessLang(langParam?: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            let newLang: string;

            if (langParam === undefined) {
                if (this.lang === i18n.langDefault) {
                    resolve(null);
                    return;
                }
                newLang = i18n.langDefault;
            } else {
                if (this.lang === langParam) {
                    resolve(null);
                    return;
                }
                newLang = langParam;
            }

            let lang = i18n.langSanitize(newLang);

            console.debug("changing language to: ", lang);

            i18n.changeLanguage(lang, (error, t) => {
                if (error) {
                    resolve(null);
                    return;
                }

                console.debug("changed language to: ", lang);

                document.documentElement.lang = lang;
                i18n.i18nFreqLangUpdate();
                resolve(lang);
            });
        });
    }

    private processLang(langParam: string | undefined) {
        if (!langParam && this.lang === i18n.langDefault)
            return;

        if (langParam === this.lang)
            return;

        this.startProcessLang(langParam ?? i18n.langDefault).then(lang => {
            if (!lang)
                return;

            this.lang = lang;

        });
    }

    setLang(newLang: string) {
        this.processLang(newLang);
    }

};

const session_ = new Language();

export default session_;

