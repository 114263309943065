import styled from 'styled-components/macro';

import theme_ from 'theme/Theme';
import Env from 'env';

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: ${theme_.pageHeaderHeight}px;
    height: ${theme_.pageHeaderHeight}px;
`;

let LogoImage

if (Env.isMobile) {
    LogoImage = styled.img.attrs({
        //src: "/images/microscope.svg",
        src: "/images/company-logo.svg",
        alt: "Tsiran",
    })`
        padding: ${theme_.pageHorzMargin}px;
        // width: ${theme_.pageHeaderHeight}px;
        // height: ${theme_.pageHeaderHeight}px;
        width: 100px;
        height: auto;
        // margin-left: 25px;
        margin-left: 45px;
        margin-top: 20px;
    `;
} else {
    LogoImage = styled.img.attrs({
        //src: "/images/microscope.svg",
        src: "/images/company-logo.svg",
        alt: "Tsiran",
    })`
        padding: ${theme_.pageHorzMargin}px;
        // width: ${theme_.pageHeaderHeight}px;
        // height: ${theme_.pageHeaderHeight}px;
        width: 100px;
        height: auto;
        // margin-left: 25px;
        margin-left: 125px;
        margin-top: 20px;
    `;
    // padding: 10px 20px;
    // width: 90px;
}


export default function PageLogo() {

    return <Container>
        <LogoImage />
    </Container>;
}
