import React from "react";


import { Typography, Box, Divider, Button } from "@mui/material"
import PageContents from "../../components/PageContents";
import { createTheme } from '@mui/material/styles';
import CareersPage from "pages/CareersPage";
import ContactUsPage from "pages/ContactPage";
import ProductsPage from "pages/ProductsPage";
import SupportPage from "pages/SupportPage";
import Env from 'env';
import theme_ from "theme/Theme";

import { HashRouter } from 'react-router-dom';

import { Link } from 'react-scroll'

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';


import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AboutUsPage from "pages/AboutUsPage";
import PartnersPage from "../PartnersPage";



const ImageComponent = muiStyled('img')({});

const theme = createTheme({
    typography: {
        fontFamily: 'Inter',

    }
});

export function selectedLang(currentLang: string) {
    if (currentLang === 'am') {
        theme_.fontType = 'monospace';
        theme_.supportPageTitleWidth = '80%';
        theme_.aboutUsImageMarginTop = 60
    } else {
        theme_.fontType = 'Inter';
        theme_.supportPageTitleWidth = '100%'
        theme_.aboutUsImageMarginTop = 40

    }
}

export function BackToTopButton() {


    const [backToTopButton, setBackToTopButton] = React.useState(false);



    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return <>

        {backToTopButton && (
            //     <Button  onClick={scrollUp} >
            //     Delete
            //   </Button>
            <Button style={{
                position: 'fixed',
                bottom: '50px',
                right: '50px',
                // height: '3px',
                // width: '9px',
                // fontSize: '80px',
                color: 'transparent',
                // border: '1px solid transparent',
                backgroundColor: 'transparent',
                outline: 'none',
                border: 'none',


                // backgroundColor: 'transparent',
                // boxShadow: '0 0 0 3pt #fd8f20',
                // borderRadius: '50%',
            }}
                onClick={scrollUp}
            ><ArrowUpwardIcon sx={{ fontSize: 70, marginTop: -5, color: '#fd8f20', }} /></Button>
        )}

    </>
}


export default function HomePage() {

    const [t] = useTranslation();



    if (Env.isMobile) {
        return <PageContents>

            <>
                {/* <ul style={{ display: 'flex', listStyle: 'none', justifyContent: 'space-around' }}>
                <li><Link activeClass="active" to="home" spy={true} smooth={true}>Home</Link></li>
                <li><Link to="about" spy={true} smooth={true}>About</Link></li>
                <li><Link to="products" spy={true} smooth={true}>Contact</Link></li>
                <li><Link to="support" spy={true} smooth={true}>Service</Link></li>
            </ul> */}

                <div id="home" >
                    <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '900px' }} src="images/background-image.jpg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 5, fontSize: 18, marginTop: 20 }}>{t('PROJECTION, MANUFACTURING')}</Typography>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 5, fontSize: 18, marginTop: 1 }}>{t('AND TESTING')}</Typography>
                    <Divider sx={{ width: '300px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 5, marginTop: 1 }}></Divider>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 5, fontSize: 50 }}>{t('WE ARE')}</Typography>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 5, fontSize: 50 }}>{t('MORE THEN')}</Typography>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 5, fontSize: 50 }}>{t('IT COMPANY')} </Typography>
                </div>


                <div id="about" >
                    <AboutUsPage />

                </div>

                <div id="products">
                    <ProductsPage />


                </div>

                <div id="support">
                    <SupportPage />


                </div>

                <div id="partners">
                    <PartnersPage />

                </div>

                <div id="contact">
                    <ContactUsPage />

                </div>

                {BackToTopButton()}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50, backgroundColor: '#D36A00', marginTop: 10, }}>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 18 }}>Copyright ©2022 All rights reserved | Tsiran-IT.am</Typography>

                </Box>

            </>
            {/* 
            <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '150%' }} src="images/background-image.png"></ImageComponent>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 18, marginTop: 20 }}>PROJECTION, MANUFACTURING</Typography>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 18, marginTop: 1 }}>AND TESTING</Typography>
            <Divider sx={{ width: '300px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 5, marginTop: 1 }}></Divider>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 50 }}>WE ARE </Typography>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 50 }}>MORE THEN </Typography>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 50 }}>IT COMPANY </Typography> */}
            {/* 
            <AboutUsPage />
            <ProductsPage />
            <SupportPage />
            <ContactUsPage /> */}
        </PageContents>

    }

    return <PageContents>

        <>
            {/* <ul style={{ display: 'flex', listStyle: 'none', justifyContent: 'space-around' }}>
                <li><Link activeClass="active" to="home" spy={true} smooth={true}>Home</Link></li>
                <li><Link to="about" spy={true} smooth={true}>About</Link></li>
                <li><Link to="products" spy={true} smooth={true}>Contact</Link></li>
                <li><Link to="support" spy={true} smooth={true}>Service</Link></li>
            </ul> */}

            <div id="home" >
                <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '975px', marginLeft: -0.0645 }} src="images/background-image.jpg"></ImageComponent>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 20, marginTop: 20 }}>{t('PROJECTION, MANUFACTURING AND TESTING')}</Typography>
                <Divider sx={{ width: '550px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 15, marginTop: 1 }}></Divider>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 90 }}>{t('WE ARE')}</Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 90, marginTop: -4 }}>{t('MORE THEN')} </Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 90, marginTop: -4 }}>{t('IT COMPANY')} </Typography>
            </div>


            <div id="about" >
                <AboutUsPage />

            </div>

            <div id="products">
                <ProductsPage />


            </div>

            <div id="support">
                <SupportPage />


            </div>

            <div id="partners">
                <PartnersPage />

            </div>

            <div id="contact">
                <ContactUsPage />

            </div>


            {BackToTopButton()}


            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50, backgroundColor: '#D36A00', marginTop: 10, }}>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 18 }}>Copyright ©2022 All rights reserved | Tsiran-IT.am</Typography>

            </Box>
        </>
    </PageContents>

    // return <PageContents>


    //     <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%' }} src="images/background-image.png"></ImageComponent>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 20, marginTop: 20 }}>PROJECTION, MANUFACTURING AND TESTING</Typography>
    //     <Divider sx={{ width: '500px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 15, marginTop: 1 }}></Divider>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 90 }}>WE ARE </Typography>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 90 }}>MORE THEN </Typography>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 90 }}>IT COMPANY </Typography>



    //     <AboutUsPage />
    //     <ProductsPage />
    //     <SupportPage />
    //     {/* <CareersPage/> */}
    //     <ContactUsPage />
    // </PageContents>
}
