import React from "react";
import Env from 'env'

import { Box, Divider, Typography } from "@mui/material";

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";


const ImageComponent = muiStyled('img')({});



export default function ContactUsPage() {

    const [t] = useTranslation();


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 1, display: 'grid' }}>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 35, marginLeft: 3 }}>{t('CONTACT US')}  </Typography>
                    <ImageComponent sx={{ position: 'absolute', width: '100%', height: 'auto', zIndex: -1 }} src="images/contact-background-image.svg"></ImageComponent>
                </Box>

                <Box>

                    <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'center',  marginLeft: 'auto', marginRight: 'auto', width: '96%' }}>

                        <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, textAlign: 'center', marginTop: 10 }}>{t('Provided below are the contact details for LIMITED LIABILITY COMPANY')}</Typography>

                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginLeft: 'auto', marginRight: 'auto', marginTop: 5, padding: 1 }}>  {/* I added paddingBottom just for DEMO */}
                        {/* <Box sx={{ width: 400, paddingBottom: 10, marginLeft: 2 }}> */}

                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                            <ImageComponent src="images/mail-icon.svg"></ImageComponent>
                            <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>info@tsiran-it.com</Typography>


                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                            <ImageComponent src="images/call-icon.svg"></ImageComponent>
                            <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>+37494438708</Typography>
                        </Box>

                        {/* </Box> */}

                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5, marginLeft: 'auto', marginRight: 'auto', width: '60%' }}>
                    <ImageComponent sx={{ marginTop: -1 }} src="images/location-icon.svg"></ImageComponent>
                    <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>{t('YEREVAN, ARMENIA  23 Aram Khachatryan st.')} </Typography>


                </Box>

            </Box>

        </Box>
        </>
    }

    return <>

        <Box sx={{ width: '100%', marginTop: 1, display: 'grid' }}>

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 35, marginLeft: 3 }}>{t('CONTACT US')}  </Typography>
                <ImageComponent sx={{ position: 'absolute', width: '100%', height: 'auto', zIndex: -1 }} src="images/contact-background-image.svg"></ImageComponent>
            </Box>

            <Box sx={{
                // display: 'grid',
                // alignItems: 'center',
                // justifyContent: 'center'
            }}>

                <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

                    <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, textAlign: 'center', marginTop: 15 }}>{t('Provided below are the contact details for LIMITED LIABILITY COMPANY')}</Typography>

                </Box>
                <Box sx={{ width: 1200, display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginLeft: 'auto', marginRight: 'auto', marginTop: 10 }}>  {/* I added paddingBottom just for DEMO */}
                    {/* <Box sx={{ width: 600, paddingBottom: 10, marginLeft: 30 }}> */}

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, width: 400 }}>
                        <ImageComponent src="images/location-icon.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>{t('YEREVAN, ARMENIA  23 Aram Khachatryan st.')} </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                        <ImageComponent src="images/call-icon.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>+37494438708</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                        <ImageComponent src="images/mail-icon.svg"></ImageComponent>
                        <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>info@tsiran-it.com</Typography>


                    </Box>
                    {/* </Box> */}

                </Box>


            </Box>

        </Box>

    </>

}

